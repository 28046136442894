import { useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import useAuthUserData from "./useAuthUserData"

const useAuthGuard = ({ requiredAdmin }: { requiredAdmin: boolean }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { user, loading } = useAuthUserData()

  useEffect(() => {
    if (!loading) {
      if (!user) {
        navigate("/", {
          state: `${location.pathname}${location.search}`,
        })
      } else if (requiredAdmin && user.rol !== "firstResponderAdmin") {
        navigate("/SearchSubscriber")
      }
    }
  }, [user, loading])
}

export default useAuthGuard

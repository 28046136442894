import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { FC, Fragment, useEffect, useState } from "react";
import { compareTwoStrings } from "string-similarity";
import { read, utils } from "xlsx";
import AlertMessageErrorUpload from "../../../components/AlertMessage/AlertMessageErrorUpload";
import { useDefaultActiveSessionLengthForAgency } from "../../../Hooks/useFirstResponderService";
import { FirstResponderData } from "../../../Interfaces/FirstResponder";
import { StepProps } from "../util";
import MappedRow from "./MappedRow";

type MappingProperties = keyof Pick<
  FirstResponderData,
  | "firstName"
  | "lastName"
  | "email"
  | "badge"
  | "city"
  | "state"
  | "address"
  | "zipCode"
>;

const MAPPING_PROPERTIES: {
  [key in MappingProperties]: string;
} = {
  firstName: "First Name",
  lastName: "Last Name",
  email: "Email",
  badge: "Badge/Employee #",
  address: "Address",
  city: "City/County",
  state: "State",
  zipCode: "ZIP Code",
};

export interface Mapping {
  property: MappingProperties;
  fileColumn: string | null;
}

interface Props extends StepProps {
  file: File | null;
  setFirstResponders: (firstResponders: FirstResponderData[]) => void;
}

const MapColumns: FC<Props> = ({
  nextStep,
  prevStep,
  file,
  setFirstResponders,
}) => {
  const { defaultActiveSessionLength } =
    useDefaultActiveSessionLengthForAgency();
  const [errorAlert, setErrorAlert] = useState("");
  const [fileColumns, setFileColumns] = useState<string[]>([]);
  const [mapping, setMapping] = useState<Mapping[]>(
    Object.keys(MAPPING_PROPERTIES).map((property) => ({
      property: property as MappingProperties,
      fileColumn: null,
    }))
  );
  const [fileData, setFileData] = useState<any | null>(null);

  useEffect(() => {
    const parseFile = async () => {
      const data = await file!.arrayBuffer();
      const workbook = read(data);
      const json = utils.sheet_to_json(
        workbook.Sheets[workbook.SheetNames[0]]
      ) as any;

      const fileColumns = json.reduce((acc: any[], curr: any) => {
        return Array.from(new Set([...acc, ...Object.keys(curr)]));
      }, []);
      setFileColumns(fileColumns);
      setFileData(json);
    };
    if (file) parseFile();
  }, [file]);

  useEffect(() => {
    if (fileColumns && fileData) {
      Object.keys(MAPPING_PROPERTIES).forEach((property) => {
        const p = property as MappingProperties;

        const fileColumn = fileColumns.find((column) => {
          const headerSimilarity = compareTwoStrings(
            column,
            MAPPING_PROPERTIES[p]
          );
          const idSimilarity = compareTwoStrings(column, p);
          return headerSimilarity > 0.5 || idSimilarity > 0.5;
        });

        if (fileColumn) {
          handleMappingFor(p)(fileColumn);
        }
      });
    }
  }, [fileColumns, fileData]);

  const handleMappingFor = (property: MappingProperties) => {
    return (fileColumn: string | null) => {
      setMapping((mapping) =>
        mapping.map((m) => (m.property === property ? { ...m, fileColumn } : m))
      );
    };
  };

  return !fileData ? (
    <div>loading...</div>
  ) : (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      textAlign="center"
      bgcolor="#fff"
      sx={{ height: "100%" }}
      px={8}
      py={4}
    >
      {errorAlert && (
        <>
          <AlertMessageErrorUpload advice={errorAlert} />
        </>
      )}
      <Grid item>
        <Typography
          fontSize={25}
          fontWeight={700}
          sx={{
            color: "#242627",
          }}
        >
          Map columns from your file to Users properties
        </Typography>
        <Typography
          fontSize={16}
          fontWeight={400}
          sx={{
            color: "#384057",
          }}
        >
          Each column header below should be mapped to a User property in
          MyMedHistory. Some of them have already been mapped based on their
          names. Anything that hasn&rsquo;t been mapped yet can be manually
          mapped to a User property with the dropdown menu.
        </Typography>
      </Grid>
      <Grid item mt={4} width="100%">
        <Divider />
      </Grid>
      <Grid
        item
        container
        direction="row"
        justifyContent="flex-start"
        textAlign="left"
        py={2.5}
        sx={{
          color: "#384057",
        }}
      >
        <Grid item xs={1}>
          <Typography fontSize={14} fontWeight={600}>
            Matched
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography fontSize={14} fontWeight={600}>
            User Property
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography fontSize={14} fontWeight={600}>
            Column From Your File
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography fontSize={14} fontWeight={600}>
            Preview Information
          </Typography>
        </Grid>
      </Grid>
      <Grid item width="100%">
        <Divider />
      </Grid>
      {Object.keys(MAPPING_PROPERTIES).map((property) => {
        const p = property as MappingProperties;
        const fileColumn = mapping.find((m) => m.property === p)?.fileColumn;
        return (
          <Fragment key={p}>
            <MappedRow
              matched={!!fileColumn}
              header={MAPPING_PROPERTIES[p]}
              previews={
                fileColumn ? fileData.map((d: any) => d[fileColumn]) : []
              }
              options={fileColumns.filter(
                (c) => mapping.find((m) => m.fileColumn === c) === undefined
              )}
              setMapping={handleMappingFor(p)}
              mapping={fileColumn}
            />
            <Grid item width="100%">
              <Divider />
            </Grid>
          </Fragment>
        );
      })}
      <Grid
        item
        container
        direction="row"
        justifyContent="space-between"
        mt={4}
      >
        <Grid item>
          <Button
            variant="outlined"
            sx={{
              color: "#000094",
              textTransform: "none",
              border: "1px solid rgba(0, 0, 148, 0.4)",
              mr: 2,
            }}
            startIcon={<ChevronLeftOutlinedIcon />}
            onClick={prevStep}
          >
            Back
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            sx={{
              color: "white",
              backgroundColor: "#000094",
              textTransform: "none",
              padding: "8px 20px",
              mr: 2,
              borderRadius: "8px",
            }}
            onClick={() => {
              if (mapping.every((m) => m.fileColumn !== null)) {
                const firstResponders = fileData.map(
                  (row: any, idx: number) => {
                    const firstResponder: any = {
                      idx,
                    };
                    mapping.forEach((m) => {
                      let value = row[m.fileColumn!]
                        ? row[m.fileColumn!].toString()
                        : "";
                      if (["email"].includes(m.property)) {
                        value = value.split(" ").join("");
                      }
                      firstResponder[m.property] = value;
                    });
                    firstResponder["activeSessionLength"] =
                      defaultActiveSessionLength;
                    return firstResponder;
                  }
                );
                setFirstResponders(firstResponders);
                nextStep();
              } else {
                setErrorAlert("missingRequiredMapping");
              }
            }}
          >
            Next Step
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MapColumns;

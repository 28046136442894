import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  FirstResponderData,
  FirstResponderResponse,
} from "../Interfaces/FirstResponder";

import {
  deleteFirstResponderbyId,
  getAllFirstResponder,
  getFirstRespondersForAgency,
  getUserByCognitoId,
  sendCode,
  updateStatusFirstResponderbyId,
  verifyCode,
} from "../Services/firstResponderService";
import useAuthUserData from "./useAuthUserData";

export const isFRExpired = (fr: FirstResponderData) => {
  const frUpdatedAt = new Date(
    fr.updatedAt ? fr.updatedAt : fr.createdAt
  ).getTime();
  const now = new Date().getTime();

  const diffTime = Math.abs(frUpdatedAt - now);

  const EIGHT_DAYS_IN_MILLIS = 8 * 24 * 60 * 60 * 1000;
  const ONE_DAY_IN_MILLIS = 1 * 24 * 60 * 60 * 1000;

  return (
    fr.otp &&
    fr.status !== "active" &&
    fr.status !== "new" &&
    fr.status !== "blocked" &&
    diffTime >
      (fr.rol === "firstResponderAdmin"
        ? ONE_DAY_IN_MILLIS
        : EIGHT_DAYS_IN_MILLIS)
  );
};

export const useFirstRespondersForAgency = () => {
  const { user, loading: loadingA } = useAuthUserData();

  const [firstResponders, setFirstResponders] = useState<FirstResponderData[]>(
    []
  );
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (user && user.agency)
      getFirstRespondersForAgency(user.agency)
        .then((res) => {
          res.forEach((fr) => {
            if (isFRExpired(fr)) {
              fr.status = "expired";
              updateStatusFirstResponderbyId(fr.id, "expired");
            }
          });
          setFirstResponders(res);
          setLoading(false);
        })
        .catch((err) => {
          setError(true);
          setLoading(false);
          console.error(err);
        });
  }, [user]);

  return { firstResponders, loading: loading || loadingA, error };
};

export const useFirstResponders = () => {
  const [firstResponders, setFirstResponders] = useState<FirstResponderData[]>(
    []
  );
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    getAllFirstResponder()
      .then((res: any) => {
        const frs = res.data as FirstResponderData[];
        frs.forEach((fr) => {
          if (isFRExpired(fr)) {
            fr.status = "expired";
            updateStatusFirstResponderbyId(fr.id, "expired");
          }
        });
        setFirstResponders(frs);
        setLoading(false);
      })
      .catch((err) => {
        setError(true);
        setLoading(false);
        console.error(err);
      });
  }, []);

  return { firstResponders, loading, error };
};

export const useDefaultActiveSessionLengthForAgency = () => {
  const { firstResponders, loading } = useFirstRespondersForAgency();

  const defaultActiveSessionLength = firstResponders.find(
    (f) => f.status === "owner"
  )?.activeSessionLength;

  return { defaultActiveSessionLength, loading };
};

export const useGetAllFirstResponder = (): FirstResponderHookProps => {
  const [result, setResult] = useState<ResultFR>({
    response: {} as FirstResponderResponse,
    isloading: false,
  });

  const getAll = useCallback((): void => {
    setResult({ response: {} as FirstResponderResponse, isloading: true });

    getAllFirstResponder()
      .then((res) => {
        setResult({
          response: (res as FirstResponderResponse) || {},
          isloading: false,
        });
      })
      .catch((err) => {
        setResult({
          response: err,
          isloading: false,
        });
        console.error(err);
      });
  }, []);

  useEffect(
    () => () => {
      getAll();
    },
    []
  );

  const { isloading, response } = result;

  return { isloading, response };
};

export const useDeleteFirstResponder = (
  users: any
): FirstResponderHookProps => {
  const [result, setResult] = useState<ResultFR>({
    response: {} as FirstResponderResponse,
    isloading: false,
  });

  const showMessageSuccess = () => {
    let message =
      "?advice=deleted&message=" +
      users
        .slice(0, 2)
        .map((x: any) => x.firstName + " " + x.lastName)
        .join(", ");
    let lengthMessage =
      users.length >= 3 ? " and " + (users.length - 2) + " more " : "";
    window.location.replace(window.location.href + message + lengthMessage);
  };

  const handleClickButton = () => {
    setResult({ response: {} as FirstResponderResponse, isloading: true });

    const usersIDToDelete = users.map(({ id }: any) => id);

    const usersToDeletePromises = usersIDToDelete.map((id: string) =>
      deleteFirstResponderbyId(id)
    );

    Promise.all(usersToDeletePromises)
      .then((res) => {
        setResult({
          response: (res as any) || [],
          isloading: false,
        });
        showMessageSuccess();
      })
      .catch((err) => {
        setResult({
          response: err,
          isloading: false,
        });
        console.error(err);
      });
  };

  const { isloading, response } = result;

  return { isloading, response, handleClickButton };
};

export const useUpdateStatusFirstResponder = (
  usersId: any,
  status: any
): FirstResponderHookProps => {
  const [result, setResult] = useState<ResultFR>({
    response: {} as FirstResponderResponse,
    isloading: false,
  });

  const handleClickButton = () => {
    setResult({ response: {} as FirstResponderResponse, isloading: true });
    updateStatusFirstResponderbyId(usersId, status)
      .then((res) => {
        setResult({
          response: (res as any) || [],
          isloading: false,
        });
      })
      .catch((err) => {
        setResult({
          response: err,
          isloading: false,
        });
        console.error(err);
      });
  };

  const { isloading, response } = result;

  return { isloading, response, handleClickButton };
};

export const useSendCode = (): FirstResponderHookProps => {
  const [result, setResult] = useState<any>({
    response: {},
    isloading: false,
  });

  const [status, setStatus] = useState({
    success: false,
    error: false,
  });

  const handleClickButton = useCallback((params: any): void => {
    setResult({ response: {}, isloading: true });
    sendCode(params)
      .then((res) => {
        setResult({
          response: res || {},
          isloading: false,
        });
        setStatus({
          success: true,
          error: false,
        });
      })
      .catch((err) => {
        setStatus({
          success: false,
          error: true,
        });
        console.error(err);
      });
  }, []);

  const { isloading, response } = result;

  return { isloading, response, status, handleClickButton };
};

export const useVerifyCode = (): FirstResponderHookProps => {
  const navigate = useNavigate();
  const [result, setResult] = useState<any>({
    response: {},
    isloading: false,
  });

  const [status, setStatus] = useState({
    success: false,
    error: false,
  });

  const handleClickButton = (...params: any): void => {
    const {
      code_confirmation,
      mfa,
      handleClose = undefined,
      setIsActive,
    } = params[0];
    setResult({ response: {}, isloading: true });
    verifyCode(code_confirmation, mfa)
      .then(async (res) => {
        setStatus({
          success: true,
          error: false,
        });

        setResult({
          response: res || {},
          isloading: false,
        });

        if (!mfa && handleClose) {
          handleClose();
          setIsActive(false);
        }
      })
      .catch((err) => {
        setResult({
          response: err,
          isloading: false,
        });
        setStatus({
          success: false,
          error: true,
        });
        console.error(err);
      });
  };

  const { isloading, response } = result;

  return { isloading, response, handleClickButton, status };
};

export const useGetUserByCognitoId = () => {
  const [result, setResult] = useState<any>({
    response: {},
    isloading: false,
  });

  const getUser = useCallback((): void => {
    setResult({ response: {}, isloading: true });

    getUserByCognitoId()
      .then((res) => {
        setResult({
          response: res || {},
          isloading: false,
        });
      })
      .catch((err) => {
        setResult({
          response: err,
          isloading: false,
        });
        console.error(err);
      });
  }, []);

  useEffect(() => {
    getUser();
  }, []);

  const { isloading, response } = result;

  return { isloading, response };
};

type ResultFR = { response: FirstResponderResponse; isloading: boolean };

interface FirstResponderHookProps {
  response: FirstResponderResponse;
  isloading?: boolean;
  handleClickButton?: (...params: any) => void;
  status?: { success: boolean; error: boolean };
}

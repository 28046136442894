import Button from "@mui/material/Button"
import Divider from "@mui/material/Divider"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import Typography from "@mui/material/Typography"
import * as React from "react"
import { useNavigate } from "react-router-dom"
import HamburguerIco from "../../Assets/hamburguermenu.svg"
import { useCognitoAwsV2 } from "../../Hooks/useCognitoAwsv2"
import { FirstResponderSession } from "../../Interfaces/FirstResponder"

export default function HamburguerMenu() {
  const [firstResponder, setFirstResponder] =
    React.useState<FirstResponderSession>()
  const [route, setRoute] = React.useState("/")
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const { currentAuthenticatedUser, signOut } = useCognitoAwsV2()
  const open = Boolean(anchorEl)
  const navigate = useNavigate()
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = async () => {
    setAnchorEl(null)
  }
  const handleClickProfile = () => {
    const frAccessCode = localStorage.getItem("AccessCodeFirstResponder")
    if (frAccessCode == "" || frAccessCode == undefined) {
      navigate("/SearchSubscriber")
    } else {
      let url = `/subscriberInfo?mmh_id=MMHMMH${frAccessCode}`
      navigate(url)
    }
  }
  const handleHelpVideos = () => {
    navigate("/helpvideos")
  }
  const handleScan = () => {
    navigate("/SearchSubscriber")
  }

  const loadForm = async () => {
    // const location = useLocation();
    // const firstResponderEmail = location.state as string;
    const user = await currentAuthenticatedUser()
    const sessionUser: FirstResponderSession = {
      firstName: user.attributes.given_name,
      lastName: user.attributes.family_name,
      email: user.attributes.email,
    }

    setFirstResponder(sessionUser)
  }

  React.useEffect(() => {
    loadForm()
  }, [])

  return (
    <div>
      <Button
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <img src={HamburguerIco} alt="logo QR" className="logo-qr" />
        <Typography
          color="#FFFFFF"
          fontSize={16}
          fontWeight={400}
          sx={{ ml: 1 }}
        >
          Hi, {firstResponder?.firstName}
        </Typography>
      </Button>
      <Menu
        id="demo-positioned-menu"
        style={{
          marginTop: "28px",
          marginLeft: "17px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.08)",
          borderRadius: "8px",
        }}
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem onClick={handleClose}>
          <label>
            <span style={{ color: "#000094" }}>
              {firstResponder?.firstName} {firstResponder?.lastName}
            </span>
            <br />
            <span style={{ color: "#858C94" }}>{firstResponder?.email}</span>
          </label>
        </MenuItem>
        <Divider />
        {/* <MenuItem onClick={handleClickProfile}>Profile</MenuItem> */}
        <MenuItem onClick={handleScan}>Scan QR code</MenuItem>
        <MenuItem onClick={handleHelpVideos}>Training Videos</MenuItem>
        <MenuItem onClick={handleClickProfile}>
          View Subscriber’s Profile
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            handleClose()
            signOut()
            navigate("/login")
          }}
        >
          Logout
        </MenuItem>
      </Menu>
    </div>
  )
}

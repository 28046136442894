import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { FC, useState } from "react";
import { STATUSES, StatusesType } from "../../pages/FRAdmin/AdminUsers";
import BlockDialog from "../BlockDialog";
import DeleteUsersDialog from "../DeleteUsersDialog";
import SendInvitesDialog from "../SendInvitesDialog";
import UnblockDialog from "../UnblockDialog";

const columns: GridColDef[] = [
  {
    field: "fullName",
    headerName: "Full Name",
    flex: 2,
    renderCell: (params) => {
      return (
        <>
          <Avatar sx={{ mr: 1.5, bgcolor: "#000094", fontSize: 16 }}>{`${
            params.row.firstName[0] || ""
          }${params.row.lastName[0] || ""}`}</Avatar>
          {`${params.row.firstName || ""} ${params.row.lastName || ""}`}
        </>
      );
    },
    valueGetter: (params) => {
      return `${params.row.firstName || ""} ${params.row.lastName || ""}`;
    },
  },
  {
    field: "email",
    headerName: "Email",
    flex: 2,
  },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
    renderCell: (params) => {
      return (
        <div
          style={{
            color: STATUSES[params.value as StatusesType].color,
            backgroundColor:
              STATUSES[params.value as StatusesType].color + "1F",
            paddingRight: "8px",
            paddingLeft: "8px",
            paddingTop: "4px",
            paddingBottom: "4px",
            borderRadius: "6px",
          }}
        >
          {params.value.toUpperCase()}
        </div>
      );
    },
  },
  {
    field: "city",
    headerName: "City/County",
    flex: 1,
  },
  {
    field: "state",
    headerName: "State",
    flex: 1,
  },
  {
    field: "zipCode",
    headerName: "Zip Code",
    flex: 1,
  },
  {
    field: "address",
    headerName: "Address",
    flex: 1,
  },
  {
    field: "updatedAt",
    headerName: "Last Updated",
    flex: 2,
    valueFormatter: (params) => {
      return new Date(
        params.value ?? params.api.getRowParams(params.id).row.createdAt
      ).toLocaleDateString();
    },
    valueGetter: (params) => {
      return params.row.updatedAt || params.row.createdAt;
    },
  },
  {
    field: "actions",
    headerName: "Actions",
    flex: 1,
    sortable: false,
    renderCell: (params) => <Actions user={params.row} />,
    align: "right",
    headerAlign: "right",
  },
];

import BlockIcon from "@mui/icons-material/Block";
import { CgUnblock } from "react-icons/cg";

const Actions = ({ user }: { user: any }) => {
  const [dialogToOpen, setDialogToOpen] = useState<
    "invite" | "delete" | "block" | "unblock" | null
  >(null);

  return (
    <>
      {user.status === "invited" || user.status === "expired" ? (
        <IconButton
          sx={{ color: "#000094" }}
          onClick={() => setDialogToOpen("invite")}
        >
          <ForwardToInboxIcon />
        </IconButton>
      ) : user.status === "active" ? (
        <IconButton
          sx={{ color: "#000094" }}
          onClick={() => setDialogToOpen("block")}
        >
          <BlockIcon />
        </IconButton>
      ) : user.status === "blocked" ? (
        <IconButton
          sx={{ color: "#000094" }}
          onClick={() => setDialogToOpen("unblock")}
        >
          <CgUnblock />
        </IconButton>
      ) : (
        <> </>
      )}
      <IconButton
        sx={{ color: "#000094" }}
        disabled={user.status === "owner"}
        onClick={() => setDialogToOpen("delete")}
      >
        <DeleteOutlineOutlinedIcon />
      </IconButton>
      <SendInvitesDialog
        reInvite={false}
        open={dialogToOpen === "invite"}
        users={[user]}
        bulk={false}
        handleClose={() => setDialogToOpen(null)}
        admin
      />
      <DeleteUsersDialog
        open={dialogToOpen === "delete"}
        users={[user]}
        handleClose={() => setDialogToOpen(null)}
      />
      <BlockDialog
        bulk={false}
        users={[user]}
        open={dialogToOpen === "block"}
        handleClose={() => setDialogToOpen(null)}
      />
      <UnblockDialog
        bulk={false}
        users={[user]}
        open={dialogToOpen === "unblock"}
        handleClose={() => setDialogToOpen(null)}
      />
    </>
  );
};

interface Props {
  users: any[];
  isloading: boolean;
}

const AdminUsersTable: FC<Props> = ({ users, isloading }) => {
  const [pageSize, setPageSize] = useState(10);

  return (
    <DataGrid
      sx={{
        color: "black",
        border: 0,
        ".MuiDataGrid-columnSeparator": {
          display: "none",
        },
        ".MuiDataGrid-row": {
          borderRadius: "4px",
          backgroundColor: "#FFFFFF",
        },
        ".MuiDataGrid-columnHeaders": {
          borderRadius: "4px",
          backgroundColor: "#E0E2E9",
        },
        "& .MuiCheckbox-root": {
          color: "#000094",
        },
        ".MuiDataGrid-columnHeaderTitle": {
          fontWeight: 400,
        },
      }}
      loading={isloading}
      rows={users ?? []}
      columns={columns}
      disableColumnMenu
      pageSize={pageSize}
      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
      rowsPerPageOptions={[10, 20, 50]}
      autoHeight
      pagination
      getRowSpacing={(_) => {
        return {
          top: 6,
        };
      }}
      disableSelectionOnClick
    />
  );
};

export default AdminUsersTable;
